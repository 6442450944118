import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Layout from 'components/_layout';
import { ListasHolder, Holder } from 'styles/pages/livros';
import Button from 'components/Button';

import Livro from 'components/Livro';
import { ILivroDTO, ISerieDTO } from 'utils/dto';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const Livros = (props: PageProps) => {
  const { location } = props;

  const [livros, setLivros] = useState<ILivroDTO[]>([]);
  const [serieData, setSerieData] = useState<ISerieDTO | undefined>(undefined);

  const loadLivros = useCallback(() => {
    const queryParams = queryString.parse(location.search);

    const { serie } = queryParams;

    if (!serie) {
      navigate('/listas-escolares/');
      return;
    }

    api.get(`/livros/serie/${serie}`).then(res => {
      const data: ILivroDTO[] = [];

      let ultimaDisciplina: string | undefined;

      for (let i = 0; i < res.data.length; i++) {
        const livro = res.data[i];

        if (!ultimaDisciplina) {
          livro.renderDisciplina = true;
        } else if (ultimaDisciplina !== livro.disciplina) {
          livro.renderDisciplina = true;
        } else {
          livro.renderDisciplina = false;
        }

        ultimaDisciplina = livro.disciplina;

        data.push(livro);
      }

      setLivros(data);
    });

    api.get(`/series/serie/${serie}`).then(res => {
      setSerieData(res.data);
    });
  }, []);

  useEffect(() => {
    loadLivros();
  }, [loadLivros]);

  useEffect(() => {}, [toast]);

  return (
    <Layout pathname={location.pathname}>
      <ListasHolder>
        <h1>Livros</h1>
        {serieData && (
          <h2>
            {serieData.name} - {serieData.etapa.name} -{' '}
            {serieData.etapa.colegio.name}
          </h2>
        )}
        <Holder>
          <ul>
            {livros &&
              serieData &&
              livros.map((livro, index) => (
                <Livro key={livro.id} livro={livro} serie={serieData} />
              ))}
          </ul>
          <Button
            onClick={() => {
              toast.info(
                'Ainda não é possível fazer a compra totalmente pelo site, entre em contato para mais informações!',
                {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              navigate(`/carrinho/`);
            }}
          >
            Ir para o carrinho
          </Button>
        </Holder>
      </ListasHolder>
    </Layout>
  );
};

export default Livros;
